@media screen and (max-width: 1400px) {
	div.MuiBox-root.talent-schedule {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 900px) {
	div.MuiBox-root.talent-schedule {
		grid-template-columns: 1fr;
	}

	div.MuiBox-root.talent-shift-card {
		grid-row: auto;
	}
}

/* START OF STYLES FOR TALENT TIMECLOCK PAGE */
@media screen and (max-width: 600px) {
	div.MuiTextField-root.timesheet-edit-startDate {
		grid-column: 1 / -1;
	}

	div.MuiTextField-root.timesheet-edit-endDate {
		grid-column: 1 / -1;
		grid-row: 2;
	}

	a.MuiButtonBase-root.timesheet-edit-cancelButton,
	a.MuiButtonBase-root.timesheet-edit-saveButton {
		grid-row: 3;
	}

	p.timesheet-time-description {
		width: 90%;
	}
}
/* END OF STYLES FOR TALENT TIMECLOCK PAGE */

/* JsonView START */
.json-view .jv-button {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}
/* JsonView END */
